import styles from '@/components/home/v2/Footer.module.css'

import ConnectContainer from '@/components/ConnectContainer'
import Link from 'next/link'
import AppStoreButtons from '@/components/ui/buttons/AppStoreButtons'
import FooterContainer from "@/components/home/v2/footer/FooterContainer";

const Footer = () => {
  const currentYear = new Date().getFullYear() // Get the current year

  return (
    <div className={styles.footer}>
      <ConnectContainer />

      <FooterContainer />


      <div className={styles.footer2}>
        <div className={styles.footerRow}>
          <div className={styles.description}>
          {
            'Future Motion Solutions Ltd t/a ClearWatt is a private limited company registered in England and Wales with number 09793739.'
          }
          </div>
        </div>
        <div className={styles.footerRow}>
          <div className={styles.copyright}>{`© ${currentYear} ClearWatt`}</div>
          <div className={styles.footerButtons}>
            <Link href="/legal/terms-and-conditions" className={styles.footerLink}>
              Terms and Conditions
            </Link>
            <Link href="/legal/privacy-policy" className={styles.footerLink}>
              Privacy Policy
            </Link>
            <Link href="/legal/cookies-notice" className={styles.footerLink}>
              Cookies Notice
            </Link>
            <Link href="/sitemap" className={styles.footerLink}>
              Sitemap
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
