import styles from '@/components/home/v2/footer/FooterContainer.module.css'
import Link from 'next/link'
import AppStoreButtons from '@/components/ui/buttons/AppStoreButtons'

const FooterContainer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerColumn}>
          <div className={styles.columnHeader}>
            <div className={styles.title}>Products</div>
          </div>
          <div className={styles.columnBody}>
            <Link href="/ev-health-test" className={styles.footerLink}>
              EV Health Test App
            </Link>
            <Link href="/ev-check" className={styles.footerLink}>
              EV Check Plus
            </Link>
            <Link href="/directory" className={styles.footerLink}>
              EV Explorer
            </Link>
            <Link href="/knowledge" className={styles.footerLink}>
              Knowledge Hub
            </Link>
            <Link href="/lite" className={styles.footerLink}>
              EV Insights Reports
            </Link>
            <Link href="/labs" className={styles.footerLink}>
              ClearWatt Labs
            </Link>
          </div>
        </div>
        <div className={styles.footerColumn}>
          <div className={styles.columnHeader}>
            <div className={styles.title}>Navigation</div>
          </div>
          <div className={styles.columnBody}>
            <Link href="/about" className={styles.footerLink}>
              About
            </Link>
            <Link href="/clearwatt-approach" className={styles.footerLink}>
              Our Approach
            </Link>
            <Link href="/blog" className={styles.footerLink}>
              Blog
            </Link>
            <Link href="/glossary" className={styles.footerLink}>
              EV Glossary
            </Link>
          </div>
        </div>
        <div className={styles.footerColumn}>
          <div className={styles.columnHeader}>
            <div className={styles.title}>Contact us</div>
            <div className={styles.description}>Contact us for any questions or inquiries</div>
          </div>
          <div className={styles.columnBody}>
            <Link href="/contact" className={styles.footerLink}>
              Contact Page
            </Link>
            <Link href="mailto:hello@clearwatt.co.uk" target="_blank" className={styles.footerLink}>
              hello@clearwatt.co.uk
            </Link>
            <div className={styles.columnBody}>
              <div className={styles.footerLink}>
                County Hall Belvedere Road, <br />
                London SE1 7PB
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footerColumn}>
          <div className={styles.columnHeader}>
            <div className={styles.title}>Follow us</div>
            <div className={styles.description}>Follow us to stay connected with our community</div>
          </div>
          <div className={styles.columnBody}>
            <Link href="https://www.linkedin.com/company/clearwatt" target="_blank" className={styles.footerLink}>
              LinkedIn
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterContainer
