import styles from './AppStoreButtons.module.css'

function AppStoreButtons({ theme = 'light', position = 'center' }) {
  const appStoreIcon = theme === 'light' ? '/AppleLightAlt3.svg' : '/AppleLightAlt1.svg'
  const googlePlayIcon = theme === 'light' ? '/GoogleLightAlt3.svg' : '/GoogleLightAlt1.svg'

  const positionClass = position === 'left' ? styles.positionLeft : styles.positionCenter

  return (
    <div className={`${styles.appStoreButtonsContainer} ${positionClass}`}>
      <a
        href={'https://apps.apple.com/gb/app/clearwatt/id6447674962'}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Download on the App Store"
      >
        <img src={appStoreIcon} alt="Download on the App Store" className={styles.appStoreIcon} />
      </a>
      <a
        href={'https://play.google.com/store/apps/details?id=com.clearwatt.beta&pli=1'}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Get it on Google Play"
      >
        <img src={googlePlayIcon} alt="Get it on Google Play" className={styles.googlePlayIcon} />
      </a>
    </div>
  )
}

export default AppStoreButtons
